<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">活动名称:</label>
					<el-input class="input-item" clearable style="margin-left:5px;width:250px" v-model="searchData.activityName"
					@keyup.enter.native="search" placeholder="请输入活动名称"></el-input>
				</div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">活动时间:</label>
					<el-date-picker v-model="applyTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
					end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<!-- <div class="filter-item">
					<label class="label">活动状态:</label>
					<el-select class="option-item" v-model="searchData.type">
						<el-option label="全部" :value="null"></el-option>
						<el-option
							v-for="item in refundStatus"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div> -->
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="search">查询</el-button>
					<button-permissions :datas="'addKill'">
						<el-button type="primary" style="width:100px;margin-right:20px;" @click="addSecondKill({secKillId:0})">添加活动</el-button>
					</button-permissions>
					<!-- <button-permissions :datas="'addKillTime'">
						<el-button type="primary" style="width:100px;" @click="groupExport">添加时段</el-button>
					</button-permissions> -->
				</div>
			</div>
		</div>
		<!-- tab切换 -->
		<el-tabs v-model="status" @tab-click="search">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="activityName" label="活动名称"></el-table-column>
				<el-table-column prop="startTime" label="开始时间"></el-table-column>
				<el-table-column prop="endTime" label="结束时间"></el-table-column>
				<el-table-column prop="activityStatus" label="活动状态"></el-table-column>
				<el-table-column prop="payTotalAmount" label="订单实付金额"></el-table-column>
				<el-table-column prop="payTotalNums" label="实付单数"></el-table-column>
				<el-table-column prop="payTotalNums" label="实付人数"></el-table-column>

				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<!-- <button-permissions :datas="'refundAfterOrderToDo'">
							<div class="table-button" @click="addSecondKill(scope.row)">编辑</div>
						</button-permissions> -->
						<button-permissions :datas="'skillDetail'">
							<div class="table-button" @click="handleDetail(scope.row)">查看</div>
						</button-permissions>
						<button-permissions :datas="'skillEdit'" v-if="scope.row.status!=2">
							<div class="table-button" @click="handleEdit(scope.row)">编辑商品</div>
						</button-permissions>
						<button-permissions :datas="'skillFinish'" v-if="scope.row.status!=2">
							<div class="table-button" :disabled="activeFinishLoading" v-loading="activeFinishLoading" @click="handleFinished(scope.row)">结束秒杀</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="searchData.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="searchData.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="total"></el-pagination>
		</div>
		
		<!-- 添加秒杀活动 -->
		<el-dialog title="添加秒杀活动" :visible.sync="secondKillDialog" width="600px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="活动名称" prop="activeName">
					<el-input v-model="ruleForm.activeName" placeholder="最多输入20个字" maxlength="20" style="width:400px"></el-input>
				</el-form-item>
				<!-- <el-form-item label="活动时间" required prop="activityTime">
					<el-date-picker v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
					range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item> -->
				<el-form-item label="活动时间:" prop="activityTime">
					<el-date-picker v-model="ruleForm.activityTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
					end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<!-- <el-form-item label="开始时间" required prop="activityTime">
					<el-select class="option-item" v-model="ruleForm.activityTime" style="width: 400px;">
						<el-option
							v-for="(item,index) in activityTimeList"
							:key="index"
							:label="item"
							:value="item"
						></el-option>
					</el-select>
				</el-form-item> -->
				<!-- <el-form-item label="结束时间" required prop="activityTime2">
					<el-select class="option-item" v-model="ruleForm.activityTime2" style="width: 400px;">
						<el-option
							v-for="(item,index) in activityTimeList"
							:key="index"
							:label="item"
							:value="item"
						></el-option>
					</el-select>
				</el-form-item> -->
				<div style="text-align: center;margin-top: 30px;">
					<el-button style="width: 120px;" @click="secondKillDialog=false">取消</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" :disabled="addLoading" v-loading="addLoading" @click="sureSecondKill">确定</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		seckillList,
		seckillUpdate,
		seckillSend,
		getBasicsSetting
	} from '@/api/goods'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		name: 'refundAfterOrder',
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				searchData: {
					activityName: '', //活动名称
					pageNo: 1,
					pageSize: 20
				},
				status: 0, //活动类型
				total: 0,
				loading: false,
				listStateList: [{
						id: 0,
						value: '所有活动',
					},
					{
						id: 1,
						value: '未开始',
					},
					{
						id: 2,
						value: '秒杀中',
					},
					{
						id: 3,
						value: '已结束',
					},
				],
				refundStatus: [{
					value: 1,
					label: '未开始'
				}, {
					value: 2,
					label: '秒杀中'
				}, {
					value: 3,
					label: '已结束'
				}],
				tableData: [],
				secondKillDialog:false,
				ruleForm: {
					activeName: '',
					activityTime: '',
					activityTime2:'',
				},
				rules: {
					activeName: [{
						required: true,
						message: '请填写活动名称',
						trigger: 'change'
					}],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					activityTime2: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
				},
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				currentRow:null,
				currentTime:'',
				activityTimeList:[],
				addLoading:false,
				timeList:[],
				activeFinishLoading:false
			}
		},
		async beforeMount() {
			var date = new Date();
			this.currentTime = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
			this.getList();
			// this.getTimeData();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					let data = this.searchData;
					if(+this.status>0){
						data.status = (+this.status) - 1;
					}else{
						data.status = null;
					}
					if (this.applyTime) {
						data.startTime = this.applyTime[0];
						data.endTime = this.applyTime[1];
					} else {
						data.startTime = '';
						data.endTime = '';
					}
					let result = await seckillList(data);
					result.data.records.map(item => {
						item.activityStatus = item.status == 0 ? '活动未开始' : item.status == 1 ? '秒杀中' : '活动已结束';
						return item;
					})
					this.tableData = result.data.records;
					this.total = result.data.total;
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			async getTimeData(){
				let res = await getBasicsSetting({});
				if(res.success){
					this.data = res.data;
					var list = res.data.secKillTime.split(',');
					this.timeList = list;
					this.activityTimeList = list.map(item=>{
						item = this.currentTime+' '+item+'点';
						return item;
					})
					// this.activityTimeList = list;
					console.log("--11111---",this.activityTimeList);
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.getList()
			},
			// 添加秒杀
			addSecondKill(row) {
				if(row.secKillId){
					this.currentRow = row;
				}else{
					this.currentRow = null;
				}
				this.ruleForm = {
					activeName:'',
					activityTime:'',
					activityTime2:''
				}
				this.secondKillDialog = true;
			},
			handleEdit(row){
				let params = {
					id: row.secKillId||0,
					atyName:row.activityName,
					atyTime:[row.startTime,row.endTime],
					type:row.status
				}
				this.publicToDetailsR(params, '/market/secondKill/secondKillEdit', this);
			},
			//跳转至详情
			handleDetail(row){
				let params = {
					id: row.secKillId||0
				}
				this.publicToDetailsR(params, '/market/secondKill/secondKillDetail', this);
			},
			//结束秒杀
			async handleFinished(row){
				this.activeFinishLoading = true;
				let _this = this;
				this.$confirm('是否确认关闭当前秒杀活动', '提示', {
					confirmButtonText: '确认关闭',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let data = {
						secKillId:row.secKillId||0
					};
					let res = await seckillSend(data);
					console.log("--------",res)
					if(res.success){
						_this.$message({
							showColse:true,
							type:'success',
							message:"秒杀已成功关闭"
						})
						_this.getList();
					}else{
						_this.$message({
							showColse:true,
							type:'error',
							message:res.alertMsg
						})
					}
					this.activeFinishLoading = false;
				}).catch(() => {
					this.activeFinishLoading = false;
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {})
			},
			
			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			sureSecondKill(){
				this.addLoading = true;
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						try {
							this.seckillUpdate();
							console.log(this.ruleForm)
						} catch (e) {
							//TODO handle the exception
						}
					}else{
						this.addLoading = false;
					}
				});
			},
			async seckillUpdate(){
				let _this = this;
				// var time1 = '';
				// var time2 = '';
				// this.activityTimeList.map((item,index)=>{
				// 	if(item ==this.ruleForm.activityTime){
				// 		time1 = this.timeList[index];
				// 	}else if(item == this.ruleForm.activityTime2){
				// 		time2 = this.timeList[index];
				// 	}
				// 	return item;
				// })
				
				let data = {
					activityName:this.ruleForm.activeName,
					secKillId:this.currentRow?this.currentRow.secKillId:null,
					// startTime:parseInt(time1)>parseInt(time2)?time2:time1,
					// endTime:parseInt(time1)<parseInt(time2)?time2:time1,
				};
				if (this.ruleForm.activityTime) {
					data.startTime = this.ruleForm.activityTime[0];
					data.endTime = this.ruleForm.activityTime[1];
				} else {
					data.startTime = '';
					data.endTime = '';
				}
				let res = await seckillUpdate(data);
				if(res.success){
					this.$message({
						showColse:true,
						type:'success',
						message:"添加成功"
					})
					this.secondKillDialog = false;
					this.searchData.pageNo=1;
					this.getList();
				}else{
					this.$message({
						showColse:true,
						type:'error',
						message:res.alertMsg
					})
				}
				setTimeout(function(){
					_this.addLoading = false;
				},1000)
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
